<template>
  <div class="form-item pb-2">
    <label class="form-item__label" :class="{ 'info-popup': description }">
      {{ $t(title) }}

      <svg
        v-svg
        v-if="description"
        v-tooltip="{
          content: $t(`${description}`),
          triggers: ['hover', 'click'],
        }"
        symbol="info"
        size="0 0 18 18"
        role="info"
      />
    </label>
    <div class="form-item__field">
      <InputRange :range="data" :min="min" :max="max" :variable="variable" :disable="disable" v-model="data" />
    </div>
  </div>
</template>
<script>
import InputRange from "./InputRange.vue";

export default {
  name: "DelaySettings",
  props: {
    delay: {
      type: Number,
      default: 0,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    variable: {
      type: [String, Object],
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  components: { InputRange },
  computed: {
    data: {
      get() {
        return this.delay;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
};
</script>
