<template>
  <div class="col-12">
    <div class="fields-section col-12 col-lg-7">
      <div class="attention-field">
        <svg class="message-icon" v-svg symbol="info" size="0 0 17 17" role="info" />
        <p class="mb-0">
          {{ $t("platform.settings.description.backbutton.info") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BackButton",
};
</script>
