<template>
  <div class="px-3">
    <ServiceWorker class="service-worker" :status="service_status" :name="service_name" :error="$v" :description="'platform.settings.description.native_web_push.service_name'" v-model="service_name" @setStatus="setStatus" />
    <button @click.prevent="sendRequest" class="btn mb-2 _mob100">
      {{ $t("save") }}
    </button>
    <button @click.prevent="openModal" :disabled="allowButton" class="btn _bg-green mb-2 ml-2 _mob100"><svg v-svg size="0 0 20 13" symbol="code"></svg> {{ $t("platforms.table.get_code") }}</button>
    <modal @closed="closeModal" :width="980" name="massModal" height="auto" :scrollable="true" classes="creative-modal overflow-removed" :shiftY="0">
      <PlatformModal :platform="platform" :showSettingsLink="false" @closeModal="closeModal" />
    </modal>
  </div>
</template>

<script>
import ServiceWorker from "@/components/PlatformSettings/ServiceWorker.vue";
import siteSettingsService from "@/api/siteSettingsService";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import platformSettingsGetCode from "@/mixins/platformSettingsGetCode";

export default {
  props: {
    platform: {
      type: Object,
    },
  },
  components: {
    ServiceWorker,
  },
  mixins: [validationMixin, platformSettingsGetCode],
  validations: {
    service_name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(120),
    },
  },
  data: () => ({
    service_name: "",
    service_status: 0,
  }),
  mounted() {
    siteSettingsService.getZeroClickPush(this.$route.params.id).then((resp) => {
      if (resp && resp.status === 200 && resp.data?.worker_name) {
        this.service_name = resp.data.worker_name;
      }
    });
  },
  methods: {
    setStatus(val) {
      this.service_status = val;
    },
    sendRequest() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.service_status !== 2) {
        this.$alert({
          title: this.$t("sent_error"),
          text: this.$t("platform.settings.service_worker_error"),
        });
        return;
      }
      const id = this.$route.params.id;
      const payload = {
        worker_name: this.service_name,
      };
      siteSettingsService.setZeroClickPush(id, payload).then((res) => {
        if (res && res.status === 400) {
          this.$alert({
            title: this.$t("sent_error"),
            text: res.message,
          });
        } else if (res && res.status === 200) {
          this.$alert({
            type: "success",
            title: `${this.$t("save_successfully")}`,
          });
          this.allowButton = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.service-worker {
  max-width: 700px;
}
</style>
